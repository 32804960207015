import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import UserAccount from '../../services/UserAccount';
import DrawerService from '../../services/Drawer';
import ClickableDiv from '../ClickableDiv';
import Scrollable from '../Scrollable';
import Version from '../../version.json';

const Wrapper = styled.div`
  background: white;
  padding: 0 1rem 2rem 1rem;
  height: 100%;
  overflow: hidden;
  position: relative;
  i {
    margin-right: 0.75rem;
  }
`;
const ContentWrapper = styled.div`
  padding: 0 1rem 2rem 1rem;
`;
const LogoWrapper = styled.div`
  display: flex;
  @media (min-width: 960px) {
    display: none;
  }
`;
const Divider = styled.div`
  background: #dbdbdb;
  height: 1px;
  padding: 0px !Important;
  margin: 1rem 0 1rem 0;
  cursor: unset;
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  > div {
    display: flex;
    align-items: center;
    > div {
      height: 32px;
      width: 128px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: left;
      background-position-y: center;
    }
  }
`;
const Selection = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.75rem;
  font-size: ${Constants.SemiSmallFontSize};

  &:hover {
    background: rgb(242, 242, 242);
  }
`;

const logo = 'https://logictrystatic.s3.amazonaws.com/images/logo062623.svg';

export default class LogicBaseDrawer extends React.PureComponent {
  getDiv = (url, text, icon) => {
    const { pathname, search } = window.location;
    return <Selection key={url}
      style={`${pathname}${search}` === url && { background: 'rgb(242, 242, 242)', fontWeight: icon && 600 || 'unset' } || { fontWeight: icon && 600 || 'unset' }}
      onClick={() => { if (DrawerService.open) { DrawerService.toggle(); } setTimeout(() => { url.startsWith('http') ? Navigation.site(url) : Navigation.navigate(url); }, 20) }}
    >
      {icon && <i className={icon} style={{ width: 16, textAlign: 'center' }}></i>}{text}
    </Selection>
  }
  render() {
    const { isLoggedIn, isDevAccount } = UserAccount;
    return (
      <Wrapper>
        <Scrollable
          vertical
          style={{ position: 'absolute', inset: 0 }}
        >
          <ContentWrapper>
            <LogoWrapper style={{ alignItems: 'center', padding: '0.25rem 0.75rem 1.5rem 1rem' }}>
              <i onClick={() => DrawerService.toggle()} className="fas fa-bars"></i>
              <Logo>
                <ClickableDiv onClick={() => { if (DrawerService.open) { DrawerService.toggle(); } Navigation.push(Navigation.home) }}>
                  <div style={{ backgroundImage: `url(${logo})` }}></div>
                </ClickableDiv>
              </Logo>
            </LogoWrapper>
            {this.getDiv('/', 'Home', 'fas fa-home')}
            {this.getDiv('/users', 'Users', 'fas fa-users')}
            {this.getDiv('/experts', 'Experts', 'fas fa-user-check')}
            {isLoggedIn && <>
              <Divider />
              {this.getDiv('/account', 'Your Account', 'fas fa-user')}
              {this.getDiv('/account?content=pins', 'Your Pins')}
              {this.getDiv('/account?content=posts', 'Your Posts')}
              {this.getDiv('/account?content=submissions', 'Your Submissions')}
            </>}
            <Divider />
            {this.getDiv('/collections', 'Collections', 'fas fa-images')}
            {[
              { title: 'Trending', url: 'trending' },
              { title: 'Politics', url: 'politics' },
              { title: 'Shopping', url: 'shopping' },
              { title: 'Startups', url: 'startups' },
            ].map(({ title, url }) => this.getDiv(`/collections?t=${url}`, title))}
            {/* <Divider />
            {this.getDiv('/search', 'Explore', 'fas fa-search')}
            {this.getDiv('/search?t=most-recent', 'Trending')} */}
            <Divider />
            {this.getDiv('/account/settings', 'Settings')}
            {this.getDiv('/account/notifications', 'Notifications')}
            {this.getDiv('/legal/privacy', 'Privacy Policy')}
            {this.getDiv('/legal/terms', 'Terms of Service')}
            {this.getDiv('https://app.logictry.com', 'Content Editor')}
            {this.getDiv('https://business.logictry.com', 'For Businesses')}
            {isDevAccount && <div style={{ padding: '0.5rem 1rem' }}>v{Version.version}</div>}
          </ContentWrapper>
        </Scrollable>
      </Wrapper>
    )
  }
}

